const ASSIGNEE_TYPE_TAB_KEYS = {
  me: 'mineCount',
  unassigned: 'unAssignedCount',
  all: 'allCount',
};

const ASSIGNEE_TYPE_TAB_KEYS_MINE = {
  me: 'mineCount',
};

const isPrivacyFeatureEnable = account => account.features.privacy === true;

const isPrivateConversationEnable = account =>
  isPrivacyFeatureEnable(account) && account.private_conversations === true;

const isPrivateContactsEnable = account =>
  isPrivacyFeatureEnable(account) && account.private_contacts === true;

const isAdminUser = user => user.role === 'administrator';

const allConversations = (account, user) =>
  isAdminUser(user) || !isPrivateConversationEnable(account);
export const getAssigneeTypeTabsByPrivateType = (user, account) => {
  if (allConversations(account, user)) return ASSIGNEE_TYPE_TAB_KEYS;

  return ASSIGNEE_TYPE_TAB_KEYS_MINE;
};

export const shouldSetContact = (user, account) => {
  return (
    isAdminUser(user) ||
    (!isAdminUser(user) && !isPrivateContactsEnable(account))
  );
};
